import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { AssetDocument } from '~/core/domain/types';
import * as PlatformService from '~/core/services/PlatformService/PlatformService';
import {
  Asset,
  CreateAssetPayload,
  CreateTemplateSetPayload,
  TemplateSet,
  UpdateTemplateByIdPayload,
  WorkflowInitiationResponse,
} from '~/core/services/PlatformService/types';

import { useStrictParams } from './useStrictParams';
import { useToast } from './useToast';

export const LocalQueryKeys = {
  CREATE_PLATFORM_ASSET: ['create-platform-asset'],
  CREATE_TEMPLATE_SET: ['platform-create-template-set'],
  DELETE_PLATFORM_ASSET: ['delete-platform-asset'],
  GENERATE_DOCUMENT_CONTENT: ['platform-generate-document-content'],
  GET_ASSET_BY_ASSET_ID: (assetId: string) => ['platform-asset-by-id', assetId],
  GET_DOCUMENT_CONTENT: (assetDocumentId: string, assetId: string) => [
    'platform-document-content',
    assetDocumentId,
    assetId,
  ],
  GET_LIST_OF_PLATFORM_ASSETS: ['platform-assets'],
  GET_TEMPLATE_SETS: ['platform-template-sets'],
  GET_TEMPLATE_SETS_BY_ID: ['platform-template-sets-by-id'],
  UPDATE_TEMPLATE_BY_ID: ['platform-update-template-by-id'],
};

export const usePlatform = ({
  allowSingleDoc,
  templateName,
  assetDocumentId,
}: {
  allowSingleDoc: boolean | undefined;
  templateName?: string;
  assetDocumentId?: string;
}) => {
  const { indId } = useStrictParams<{ indId: string }>();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { data: platformTemplateSets = [] } = useQuery<TemplateSet[], Error>(
    {
      queryKey: LocalQueryKeys.GET_TEMPLATE_SETS,
      queryFn: async () => await PlatformService.getTemplateSets(),
      enabled: !!allowSingleDoc,
    },
    queryClient,
  );

  let templateSetId = '';
  if (platformTemplateSets.length > 0) {
    templateSetId =
      platformTemplateSets.find(
        (templateSet) => templateSet.name === templateName,
      )?.id ?? '';
  }

  const { data: platformTemplateSetById = null } = useQuery<TemplateSet, Error>(
    {
      queryKey: LocalQueryKeys.GET_TEMPLATE_SETS_BY_ID,
      queryFn: async () =>
        await PlatformService.getTemplateSetByTemplateSetId({ templateSetId }),
      enabled: !!allowSingleDoc && !!templateSetId,
    },
    queryClient,
  );

  const createTemplateSet = useMutation<CreateTemplateSetPayload, Error, any>({
    mutationFn: async (data) => {
      return await PlatformService.createTemplateSet(data);
    },
    onMutate: () => toast.success('We are creating your template...'),
    onSuccess: (data) => {
      const queryKey = LocalQueryKeys.CREATE_TEMPLATE_SET;
      queryClient.setQueryData(queryKey, data);
      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({
        queryKey: LocalQueryKeys.GET_TEMPLATE_SETS_BY_ID,
      });
      queryClient.invalidateQueries({
        queryKey: LocalQueryKeys.GET_TEMPLATE_SETS,
      });
      toast.success('Your template has been created!');
    },
    onError: () =>
      toast.error(
        'We ran into some issues and could not create your template. Please try again.',
      ),
  });

  const deleteTemplateSetById = useMutation<Record<string, string>, Error, any>(
    {
      mutationFn: async (data) => {
        return await PlatformService.deleteTemplateSetByTemplateSetId(data);
      },
      onSuccess: (data) => {
        const queryKey = LocalQueryKeys.CREATE_TEMPLATE_SET;
        queryClient.setQueryData(queryKey, data);
        queryClient.invalidateQueries({ queryKey });
        queryClient.invalidateQueries({
          queryKey: LocalQueryKeys.GET_TEMPLATE_SETS_BY_ID,
        });
        queryClient.invalidateQueries({
          queryKey: LocalQueryKeys.GET_TEMPLATE_SETS,
        });
        toast.success('Your template has been deleted!');
      },
      onError: () =>
        toast.error(
          'We ran into some issues and could not delete your template. Please try again.',
        ),
    },
  );

  const updateTemplateSetById = useMutation<any, Error, any>({
    mutationFn: async (data) => {
      return await PlatformService.updateTemplateSetByTemplateSetId(data);
    },
    onSuccess: (data) => {
      const queryKey = LocalQueryKeys.CREATE_TEMPLATE_SET;
      queryClient.setQueryData(queryKey, data);
      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({
        queryKey: LocalQueryKeys.GET_TEMPLATE_SETS_BY_ID,
      });
      queryClient.invalidateQueries({
        queryKey: LocalQueryKeys.GET_TEMPLATE_SETS,
      });
      toast.success('Your template has been updated!');
    },
    onError: () =>
      toast.error(
        'We ran into some issues and could not update your template. Please try again.',
      ),
  });

  const updateTemplateById = useMutation<UpdateTemplateByIdPayload, Error, any>(
    {
      mutationFn: async (data) => {
        return await PlatformService.updateTemplateById(data);
      },
      onSuccess: (data) => {
        const queryKey = LocalQueryKeys.UPDATE_TEMPLATE_BY_ID;
        queryClient.setQueryData(queryKey, data);
        queryClient.invalidateQueries({ queryKey });
        queryClient.invalidateQueries({
          queryKey: LocalQueryKeys.GET_TEMPLATE_SETS_BY_ID,
        });
        toast.success('Your template has been saved successfully!');
      },
      onError: () =>
        toast.error(
          'We ran into some issues and your template was not saved. Please try again.',
        ),
    },
  );

  const { data: platformAssets = [] } = useQuery<Asset[], Error>(
    {
      queryKey: LocalQueryKeys.GET_LIST_OF_PLATFORM_ASSETS,
      queryFn: async () => await PlatformService.getListOfAssets(),
      enabled: !!allowSingleDoc,
    },
    queryClient,
  );

  const createPlatformAsset = useMutation<CreateAssetPayload, Error, any>({
    mutationFn: async (data) => {
      return await PlatformService.createAsset(data);
    },
    onMutate: () => toast.success('We are creating your program...'),
    onSuccess: (data) => {
      const queryKey = LocalQueryKeys.CREATE_PLATFORM_ASSET;
      queryClient.setQueryData(queryKey, data);
      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({
        queryKey: LocalQueryKeys.GET_LIST_OF_PLATFORM_ASSETS,
      });
      toast.success('Your program has been created successfully!');
    },
    onError: () =>
      toast.error('We could not create your program. Please try again.'),
  });

  const assetId =
    platformAssets.find((asset) => asset.ind_id == indId)?.id ?? '';

  const { data: assetById = null, isLoading: assetByIdIsLoading } = useQuery<
    Asset,
    Error
  >(
    {
      queryKey: LocalQueryKeys.GET_ASSET_BY_ASSET_ID(assetId),
      queryFn: async () =>
        await PlatformService.getAssetById({
          assetId,
        }),
      enabled: !!allowSingleDoc && !!assetId,
    },
    queryClient,
  );

  const generateDocumentContent = useMutation<
    WorkflowInitiationResponse,
    Error,
    any
  >({
    mutationFn: async (data) => {
      const response = await PlatformService.generateDocumentContent(data);
      if (!response) {
        throw Error('No response received from the generate document request');
      }
      return response;
    },
    onSuccess: (data) => {
      const queryKey = LocalQueryKeys.GENERATE_DOCUMENT_CONTENT;
      queryClient.setQueryData(queryKey, data);
      queryClient.invalidateQueries({ queryKey });
      toast.success(
        'Your draft is in progress. We’ll let you know when it’s ready to review.',
      );
    },
    onError: () =>
      toast.error(
        'There was an error attempting to generate content. Please try again.',
      ),
  });

  const { data: documentContent = null } = useQuery<AssetDocument, Error>(
    {
      queryKey: LocalQueryKeys.GET_DOCUMENT_CONTENT(
        assetDocumentId || '',
        assetId,
      ),
      queryFn: async () =>
        await PlatformService.getDocumentContent({
          assetId,
          assetDocumentId: assetDocumentId || '',
        }),
      enabled: !!allowSingleDoc && !!assetDocumentId && !!assetId,
    },
    queryClient,
  );

  const refreshDocumentContent = async (assetDocumentId: string) => {
    const queryKey = LocalQueryKeys.GET_DOCUMENT_CONTENT(
      assetDocumentId,
      assetId,
    );
    await queryClient.invalidateQueries({ queryKey });
  };

  const deletePlatformAsset = useMutation<any, Error, any>({
    mutationFn: async (data) => {
      return await PlatformService.deleteAssetById(data);
    },
    onSuccess: (data) => {
      const queryKey = LocalQueryKeys.DELETE_PLATFORM_ASSET;
      queryClient.setQueryData(queryKey, data);
      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({
        queryKey: LocalQueryKeys.GET_LIST_OF_PLATFORM_ASSETS,
      });
      toast.success('Your program was successfully deleted.');
    },
    onError: () =>
      toast.error('We were unable to delete your program. Please try again.'),
  });

  const updatePlatformAsset = useMutation<any, Error, any>({
    mutationFn: async (data) => {
      return await PlatformService.updateAssetById(data);
    },
    onMutate: async (variables) => {
      return { data: variables };
    },
    onSuccess: (_, variables) => {
      const queryKey = LocalQueryKeys.DELETE_PLATFORM_ASSET;
      queryClient.setQueryData(queryKey, variables);
      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({
        queryKey: LocalQueryKeys.GET_LIST_OF_PLATFORM_ASSETS,
      });
      queryClient.invalidateQueries({
        queryKey: LocalQueryKeys.GET_ASSET_BY_ASSET_ID(variables.assetId),
      });
      toast.success('Your program was successfully updated.');
    },
    onError: () =>
      toast.success('We were unable to update your program. Please try again.'),
  });

  return {
    assetById,
    assetByIdIsLoading,
    documentContent,
    deletePlatformAsset: deletePlatformAsset.mutate,
    deleteTemplateSetById: deleteTemplateSetById.mutate,
    createPlatformAsset: createPlatformAsset.mutate,
    createdAssetIsLoading: createPlatformAsset.isPending,
    createTemplateSet: createTemplateSet.mutate,
    createdTemplateSetIsLoading: createTemplateSet.isPending,
    generateDocumentContent: generateDocumentContent.mutate,
    platformAssets,
    platformTemplateSets,
    platformTemplateSetById,
    refreshDocumentContent,
    updatePlatformAsset: updatePlatformAsset.mutate,
    updateTemplateById: updateTemplateById.mutate,
    updateTemplateSetById: updateTemplateSetById.mutate,
  };
};
