import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export type ReportedGenerationError = {
  indId: string;
  lastUpdatedAt: string;
  section: string;
};

interface LocalStorageStore {
  lastOpenedDocumentSectionNumber: string;
  lastOpenedDocumentId: string;
  lastOpenedTemplateSection: string;
  lastOpenedDataRoomTab: string;
  reportedGenerationErrors: ReportedGenerationError[];

  setReportedGenerationErrors: (
    reportedGenerationErrors: ReportedGenerationError[],
  ) => void;
  setLastOpenedDocumentSectionNumber: (
    lastOpenedDocumentSectionNumber: string,
  ) => void;
  setLastOpenedDocumentId: (lastOpenedDocumentId: string) => void;
  setLastOpenedTemplateSection: (lastOpenedTemplateSection: string) => void;
  setLastOpenedDataRoomTab: (lastOpenedDataRoomTab: string) => void;
}

const useLocalStorageStore = create<LocalStorageStore>()(
  devtools(
    persist(
      (set, get) => ({
        lastOpenedDocumentSectionNumber: '',
        setLastOpenedDocumentSectionNumber: (
          lastOpenedDocumentSectionNumber,
        ) => {
          if (
            get().lastOpenedDocumentSectionNumber ===
            lastOpenedDocumentSectionNumber
          ) {
            return;
          }
          return set({ lastOpenedDocumentSectionNumber });
        },
        lastOpenedDocumentId: '',
        setLastOpenedDocumentId: (lastOpenedDocumentId) => {
          if (get().lastOpenedDocumentId === lastOpenedDocumentId) {
            return;
          }
          return set({ lastOpenedDocumentId });
        },
        lastOpenedTemplateSection: '',
        setLastOpenedTemplateSection: (lastOpenedTemplateSection: string) => {
          if (get().lastOpenedTemplateSection === lastOpenedTemplateSection) {
            return;
          }
          return set({ lastOpenedTemplateSection });
        },

        lastOpenedDataRoomTab: '0', // Default to Overview tab
        setLastOpenedDataRoomTab: (lastOpenedDataRoomTab: string) => {
          if (get().lastOpenedDataRoomTab === lastOpenedDataRoomTab) {
            return;
          }
          return set({ lastOpenedDataRoomTab });
        },

        reportedGenerationErrors: [],
        setReportedGenerationErrors: (reportedGenerationErrors) => {
          return set({ reportedGenerationErrors });
        },
      }),
      {
        name: 'last-openned-document-section-number',
      },
    ),
  ),
);

// Hooks to use specific state
export const useLastOpenedDocumentSectionNumber = () =>
  useLocalStorageStore((state) => state.lastOpenedDocumentSectionNumber);

export const useLastOpenedDocumentId = () =>
  useLocalStorageStore((state) => state.lastOpenedDocumentId);

export const useLastOpenedTemplateSection = () =>
  useLocalStorageStore((state) => state.lastOpenedTemplateSection);

export const useLastOpenedDataRoomTab = () =>
  useLocalStorageStore((state) => state.lastOpenedDataRoomTab);

export const useReportedGenerationErrors = () =>
  useLocalStorageStore((state) => state.reportedGenerationErrors);

// Hook for actions (setters)
export const useLocalStorageStoreActions = () =>
  useLocalStorageStore((state) => ({
    setLastOpenedDocumentSectionNumber:
      state.setLastOpenedDocumentSectionNumber,
    setLastOpenedDocumentId: state.setLastOpenedDocumentId,
    // @j-weave: I think we should likely be pairing state getters/setters in the store,
    // with a provider or hook accessing this store (vs. where these typically interact directly at the page level).
    // I've left this consistent with the existing patterning prior to a refactoring.
    setLastOpenedTemplateSection: state.setLastOpenedTemplateSection,
    setLastOpenedDataRoomTab: state.setLastOpenedDataRoomTab,
    setReportedGenerationErrors: state.setReportedGenerationErrors,
  }));
